export const studentPhotos = [
  {
    src: "https://lh3.google.com/u/0/d/1S0jd9EBjjRo0gCWh0P1KdAosvSIVbcGi=w958-h911-iv1",
    width: 4,
    height: 3,
    title: "Circle A"
  },
  {
    src: "https://lh3.google.com/u/0/d/1eGyFW7ls_drcMBCQ5XGDkwOAfR7QJb26=w958-h911-iv1",
    width: 4,
    height: 3,
    title: "Own Charcoal 12x16 Paper"
  },

  {
    src: "https://lh3.google.com/u/0/d/162MXyzH_dXsbgPZOWRQhpaN2et_HDPra=w532-h927-iv1",
    width: 3,
    height: 4,
    title: "Simone"
  },
  {
    src: "https://lh3.google.com/u/0/d/1QKulZIMqYBOOZcWB9t3emORJUhh9A4fe=w532-h927-iv1",
    width: 1,
    height: 1,
    title: "Simone 2"
  },
  {
    src: "https://lh3.google.com/u/0/d/1C13W0roMUsyp5eyFRNKGkavSwiP2NDRP=w1494-h937-iv1",
    width: 3,
    height: 4,
    title: "Sunflowers"
  },
  {
    src: "https://lh3.google.com/u/0/d/1VQ0Z9YUNLa3mt1R7H-mf5FHKL-rjliMz=w1654-h937-iv1",
    width: 4,
    height: 3,
    title: "Toucan Color Pencil 10x12 Paper"
  },
  {
    src: "https://lh3.google.com/u/0/d/1DH046tCI4v7vnPLNF9QYfX5BdeOmK5hc=w1287-h937-iv1",
    width: 4,
    height: 3,
    title: "Winterscape"
  }
];
